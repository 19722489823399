import * as React from "react"
import AppLayout from "../../components/app/app-layout"
import Seo from "../../components/seo";
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"
import HolidayNotice from "../../components/common/holiday_notice";

const formUrl =
  "https://docs.google.com/forms/d/1Y_F4VEPVLiQWwottloc_JUjnwSq3Apw8AdUkmZflrK4/formResponse"

const Contact = () => {
  const {t} = useTranslation();

  const title = t('contact.title');

  return (
    <AppLayout>
      <Seo title={title} isIndex={false} />
      <article className="container page-article-main contact-page">
        <HolidayNotice/>
        <header>
          <h1>{title}</h1>
        </header>

        <section>
          <p>
            {t("contact.lead.item1")}
            <br />
            {t("contact.lead.item2")}
          </p>

          <form action={formUrl} method="POST" id="js-app-contact-form-wrap">
            <div>
              <label htmlFor="entry.518793796">{t('contact.form.nameLabel')}</label>
              <input
                type="text"
                name="entry.518793796"
                id="entry.518793796"
                data-contact-type="js-contact-name"
                required
              />
            </div>

            <div>
              <label htmlFor="entry.1584384073">{t('contact.form.mailLabel')}</label>
              <input
                type="email"
                name="entry.1584384073"
                id="entry.1584384073"
                data-contact-type="js-contact-email"
                required
              />
            </div>

            <div class="divider"></div>

            {/* google-formの集計の都合上、valueは翻訳しない */}
            <div>
              <label htmlFor="entry.993209168">{t("contact.form.kindOf.label")}</label>
              <select
                name="entry.993209168"
                id="entry.993209168"
                data-contact-type="js-contact-menu"
                required
              >
                <option
                  data-contact-placeholder={t("contact.form.kindOf.option1.placeholder")}
                  value="質問"
                >
                  {t("contact.form.kindOf.option1.value")}
                </option>

                <option
                  data-contact-placeholder={t("contact.form.kindOf.option2.placeholder")}
                  value="要望"
                >
                  {t("contact.form.kindOf.option2.value")}
                </option>

                <option
                  data-contact-placeholder={t("contact.form.kindOf.option3.placeholder")}
                  value="エラー報告"
                >
                  {t("contact.form.kindOf.option3.value")}
                </option>

                <option
                  data-contact-placeholder={t("contact.form.kindOf.option4.placeholder")}
                  value="その他"
                >
                  {t("contact.form.kindOf.option4.value")}
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="entry.424111308">{t("contact.form.contactDescriptionLabel")}</label>
              <textarea
                rows="9"
                name="entry.424111308"
                id="entry.424111308"
                data-contact-type="js-contact-body"
                required
              ></textarea>
            </div>

            <p>{t("contact.footer.item1")}</p>
            <p>{t("contact.footer.item2")}</p>

            <p class="static-page-note-text">
              {t("contact.footer.notice.item1")}
            </p>
            <p class="static-page-note-text">
              {t("contact.footer.notice.item2")}
            </p>

            <div class="static-page-button-wrap">
              <button
                type="submit"
                class="static-page-button"
                id="js-app-contact-form-submit-button"
              >
                {t("contact.form.submit")}
              </button>
            </div>

            <div id="js-notice-api-error"></div>
          </form>
        </section>
      </article>
    </AppLayout>
  )
}

export default Contact

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
